import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const RelateVideo = ({ videoId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  
  const fetchData = useCallback(async () => {

    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

  try {

    await new Promise(resolve => setTimeout(resolve, 500));

    const response = await axios.get(`/relatevideo/${videoId}`);
      if (response.data.status === 200) {
        setData(response.data.data);
      } else {
        console.error('Failed to fetch related videos:', response.data);
        setError(true);
      }
    } catch (error) {
      console.error('Error fetching Home data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [videoId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return (
      <ErrorRetry title={'推荐视频'} onClick={fetchData} />
    );
  }

  return (
    <>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">
        推荐视频
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
        {data.map((item) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4">
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(300, 200)}
                errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                width={300}
                height={200}
              />
              <p className="text-center mt-2">{item.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default RelateVideo;
