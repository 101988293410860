import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailVideo from './DetailVideo';
import DetailPhotoFiction from './DetailPhotoFiction';
import { NoDetail, LoadingText } from '../components/ErrorRetry';

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`/detail/${id}`);
        if (response.data.status === 200) {
          const { id, title, typename } = response.data.data;
          if (id && title && typename) {
            setData(response.data.data);
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error || !data) {
    console.log(error)
    return (
      <NoDetail message={"找不到任何内容"} />
    );
  }
  const { typename } = data;

  if (['大陆','三级','日韩','欧美','动漫','短片'].includes(typename)) {
    return <DetailVideo data={data} />;
  } else if (["小说",'漫画','色图','图片'].includes(typename)) {
    return <DetailPhotoFiction data={data} />;
  } else {
    return <NoDetail message={"本内容已下架"} />;
  }
};

export default Detail;
